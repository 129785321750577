import React, { useState, Fragment }  from 'react';
import logo from '../images/helmet_logo.png';
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import Background from './Background'
import Scores from './Scores'
import { ParallaxBanner } from 'react-scroll-parallax';

import {
  Container, Navbar, Nav, NavDropdown
} from 'react-bootstrap';

function Header(props) {
  const [change, setChange] = useState(false);
  const [changeHeader, setChangeHeader] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState('2024');
  const [dropdownTheme, setDropdownTheme] = useState('Original');
  const changePosition = 30;
  const changeHeaderPosition = (document.documentElement.clientHeight - changePosition);
  
  let position = useWindowScrollPosition();
  // position == { x: 0, y: 0 }
  
  if (position.y > changePosition && !change) {
    setChange(true);
  }
  
  if (position.y <= changePosition && change) {
    setChange(false);
  }

  if (position.y > changeHeaderPosition && !changeHeader) {
    setChangeHeader(true);
  }

  if (position.y <= changeHeaderPosition && changeHeader) {
    setChangeHeader(false);
  }

  let style = {
    opacity: changeHeader ? "1.0" : (change ? "0.55" : "0.98"),
    transition: "400ms ease",
    //height: change ? "50px" : "60px",
  };

  const handleSelect=(e)=>{
    setDropdownTitle(e.substr(8));
  }
  const handleTheme=(e)=>{
    setDropdownTheme(e.substr(1));
  }
  
  return (
    <Fragment>
    <header>
      <Navbar collapseOnSelect bg="light" expand="md" variant="light" fixed="top" style={style} className="myNav">
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" src={logo} width="36" className="d-inline-block align-top" />{' '}
          </Navbar.Brand>
          <Navbar.Brand href="#home" className="d-none d-sm-none d-md-block">HelmetLeague</Navbar.Brand>
          <Navbar.Toggle className="opacity-5" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav >
              <NavDropdown title={dropdownTheme} onSelect={handleTheme} id="collasible-nav-dropdown" className="">
                <NavDropdown.Item href="#Original" id="original">Original</NavDropdown.Item>
                <NavDropdown.Item href="#Throwback" id="throwback">Throwback</NavDropdown.Item>
                <NavDropdown.Item href="#Speed" id="speed">Speed</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={dropdownTitle} onSelect={handleSelect} id="collasible-nav-dropdown" className="myNav">
                <NavDropdown.Item href="#action/2023" id="2022">2024</NavDropdown.Item>
                <NavDropdown.Item href="#action/2023" id="2022">2023</NavDropdown.Item>
                <NavDropdown.Item href="#action/2022" id="2022">2022</NavDropdown.Item>
                <NavDropdown.Item href="#action/2021" id="2021">2021</NavDropdown.Item>
                <NavDropdown.Item href="#action/2019" id="2019">2019</NavDropdown.Item>
                <NavDropdown.Item href="#action/2018" id="2018">2018</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#matchups" className="myNav">Matchups</Nav.Link>
              <Nav.Link href="#standings" className="myNav">Standings</Nav.Link>
              <Nav.Link href="#leagues" className="myNav">Leagues</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    <Background />
    <ParallaxBanner
            layers={[{ image: '/img/helmet.jpg', speed: -8 }]}
            className="helmets aspect-[2/1]"
    />
   <Scores dropdownTitle={dropdownTitle} dropdownTheme={dropdownTheme}/>
  </Fragment>
  );
}

export default Header;
