import React, { Fragment } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from './components/Header'

import './bootstrap/dist/css/bootstrap.min.css';

import './App.css';

const App = () => (
  <Fragment>
    <ParallaxProvider>
        <Header />   
      </ParallaxProvider>
  </Fragment>
);

export default App;
