import React, { useState, useEffect }  from 'react';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';

import {
    Container
  } from 'react-bootstrap';

function Standings(props) {
    const [leagueStandings, setLeagueStandings] = useState([]);

    /*useEffect(()=>{
      const getLeagueStandings = async () => {
          const leagueStandings = await axios.get('https://server.helmetleague.com/standings' + props.dropdownTitle)
              .then(res => {
                setLeagueStandings(res.data);
              })
      }
      // call the function
      getLeagueStandings()
      // make sure to catch any error
      .catch(console.error);;
     
  }, [])*/
  useEffect(()=>{
    const getLeagueStandings = async () => {
        const matchupleagueStandings = await axios.get((`https://server.helmetleague.com/standings`), {
            params: { 
                leagueNo: "1",
                yearNo: `${props.dropdownTitle}`, 
            }
        })
            .then(res => {
                setLeagueStandings(res.data);
            })
    }
            // call the function
            getLeagueStandings()
            // make sure to catch any error
            .catch(console.error);;
}, [])

    const columns = [{
        dataField: 'userName',
        text: 'Player'        
      }, {
        dataField: 'leagueWins',
        text: 'Wins'        
      }, {
        dataField: 'leagueLosses',
        text: 'Losses'        
      }, {
        dataField: 'leagueTies',
        text: 'Ties'
      }];

    return(
        <div id="standings" className="vh-100 bg-light">
            <Container className="pb-3 pt-3">
                <div className="text-center shiftDown"> 
                    <h3 className="text-center mb-3 mt-3 py-2">{props.leagueName} {props.dropdownTitle} Standings</h3>
                        <BootstrapTable 
                            keyField="id"
                            data={ leagueStandings }
                            columns={ columns }
                            striped
                            hover
                            condensed
                            bootstrap4 
                        />
                </div>
            </Container>
        </div>
    );
    
}

export default Standings;