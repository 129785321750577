import React from 'react';

import {
    Button
} from 'react-bootstrap';

const Background = () => (
    <div id="home" className="pt-5 text-center text-white">      
    <div className="align-middle mt-5 pb-5 pt-5">
    <div className="pb-5 pt-5 row">
    <div className="col-lg-9 col-xl-8 me-auto ms-auto mt-5 pb-5 pt-5">
        <p className="h6 text-primary text-uppercase visually-hidden">The Latest</p>
        <h1 className="display-4 fw-bold mt-4 mb-3">Helmet League</h1>
        <p className="fw-light lead mb-4">Picking tiny plastic helmets since 2003.</p>
        <Button className="btn" variant="outline-light" href="#matchups">Weekly Matchups</Button>{' '}
        <p> </p>
      
    </div>
    </div>
    </div>
    </div>
);

export default Background;

// <Button className="btn" variant="outline-light" href="https://helmetleague.com/squares">Steve's March Madness Squares</Button>{' '}
