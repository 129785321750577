import React from 'react';

import {
    Container, Row, Col
} from 'react-bootstrap';

function RightCard (props) {
    let style={
        border: '0px solid transparent',
    }

    if ((props.teamWin) == '2') {
        style = {
            border: '2px solid #339966',
        };
      } else if ((props.teamWin) == '1') {
        style = {
            border: '2px solid #db0000',
        };
      } else if ((props.teamWin) == '3') {
        style = {
            border: '2px solid #ffff33',
        };
      } else {
        style = {
            border: '0px solid transparent',
        };
      }

    return(
        <div className="">
            <h5 className="text-center text-muted">AWAY</h5>
            <div className="bg-white gx-3 row shadow me-2 loss" style={style}> 
                <div className="align-self-center column m-1 p-1" style={{ maxWidth: '45%' }}>
                    <div className="text-center">
                        <img className="thumb-img" alt={props.awaySelectionTeam} src={`/img/${props.dropdownTheme}/${props.awaySelectionTeam}.png`} width="100" style={{ transform: `rotateY(180deg)` }}/>
                    </div>
                </div>
                <div className="column" style={{ maxWidth: '50%' }}>
                    <div className="text-secondary mt-2" align="start">
                        <small className="text-muted">{props.awayTeam}</small>
                        <h6 className="small">{props.awayWins}-{props.awayLosses}-{props.awayTies}</h6>
                    </div>
                    <div className="text-secondary mt-2" align="start">
                        <small className="text-muted">{props.awaySelectionCity}</small>
                        <h6 className="my-0">{props.awaySelectionTeam}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RightCard;
