import React, { useState, useEffect }  from 'react';
import axios from "axios";
import LeftCard from './LeftCard';
import RightCard from './RightCard';
import { Row, Col } from 'react-bootstrap';

function Week(props) {
    
    const [matchups, setMatchups] = useState([]);
    const [standings, setStandings] = useState([]);
    const [change, setChange] = useState(false);
    const [change2, setChange2] = useState(false);
    
    /*useEffect(()=>{
        const getMatchupData = async () => {
            const matchupResult = await axios.get("https://server.helmetleague.com/" + props.selectedYear + "leagueMatchupsWeek" + props.weekNo)
                .then(res => {
                    setMatchups(res.data);
                })
        }
        // call the function
        getMatchupData()
        // make sure to catch any error
        .catch(console.error);;
       
    }, [])*/
    
    useEffect(()=>{
        const getMatchupData = async () => {
            const matchupResult = await axios.get((`https://server.helmetleague.com/leagueMatchups`), {
                params: { 
                    weekNo: `${props.weekNo}`, 
                    leagueNo: "1",
                    yearNo: `${props.selectedYear}`, 
                }
            })
                .then(res => {
                    setMatchups(res.data);
                })
        }
                // call the function
                getMatchupData()
                // make sure to catch any error
                .catch(console.error);;
    }, [])

    const homeTeam = matchups.map(matchup => matchup.homeTeam);
    const awayTeam = matchups.map(matchup => matchup.awayTeam);
    const homeSelectionCity = matchups.map(matchup => matchup.homeSelectionCity);
    const homeSelectionTeam = matchups.map(matchup => matchup.homeSelectionTeam);
    const awaySelectionCity = matchups.map(matchup => matchup.awaySelectionCity);
    const awaySelectionTeam = matchups.map(matchup => matchup.awaySelectionTeam);
    const teamWin = matchups.map(matchup => matchup.teamWin);
    const homeWins = matchups.map(matchup => matchup.homeWins);
    const homeLosses = matchups.map(matchup => matchup.homeLosses);
    const homeTies = matchups.map(matchup => matchup.homeTies);
    const awayWins = matchups.map(matchup => matchup.awayWins);
    const awayLosses = matchups.map(matchup => matchup.awayLosses);
    const awayTies = matchups.map(matchup => matchup.awayTies);

    const leagueYear = props.selectedYear;
    const changeVis = '2019';
    const changeVis2 = '2023';

    if (leagueYear >= changeVis && !change) {
        setChange(true);
      }
      
    if (leagueYear < changeVis && change) {
        setChange(false);
      }

    if (leagueYear >= changeVis2 && !change2) {
        setChange2(true);
    }
      
    if (leagueYear < changeVis2 && change2) {
        setChange2(false);
      }
 
    let style = {
        display: change ? "inline-block" : "none",
        //transition: "400ms ease",
        //height: change ? "50px" : "60px",
      };
      let style2 = {
        display: change2 ? "inline-block" : "none",
        //transition: "400ms ease",
        //height: change ? "50px" : "60px",
      };
    return (
        <div>
            <h3 className="mb-3 mt-3 py-2">{props.selectedYear} {props.leagueName} - Week {props.weekNo} Match Ups</h3>
                <Row>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[0]} homeSelectionCity={homeSelectionCity[0]} homeSelectionTeam={homeSelectionTeam[0]} selectedYear={props.selectedYear} teamWin={teamWin[0]} homeWins={homeWins[0]} homeLosses={homeLosses[0]} homeTies={homeTies[0]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[0]} awaySelectionCity={awaySelectionCity[0]} awaySelectionTeam={awaySelectionTeam[0]} selectedYear={props.selectedYear} teamWin={teamWin[0]} awayWins={awayWins[0]} awayLosses={awayLosses[0]} awayTies={awayTies[0]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[1]} homeSelectionCity={homeSelectionCity[1]} homeSelectionTeam={homeSelectionTeam[1]} selectedYear={props.selectedYear} teamWin={teamWin[1]} homeWins={homeWins[1]} homeLosses={homeLosses[1]} homeTies={homeTies[1]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[1]} awaySelectionCity={awaySelectionCity[1]} awaySelectionTeam={awaySelectionTeam[1]} selectedYear={props.selectedYear} teamWin={teamWin[1]} awayWins={awayWins[1]} awayLosses={awayLosses[1]} awayTies={awayTies[1]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[2]} homeSelectionCity={homeSelectionCity[2]} homeSelectionTeam={homeSelectionTeam[2]} selectedYear={props.selectedYear} teamWin={teamWin[2]} homeWins={homeWins[2]} homeLosses={homeLosses[2]} homeTies={homeTies[2]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[2]} awaySelectionCity={awaySelectionCity[2]} awaySelectionTeam={awaySelectionTeam[2]} selectedYear={props.selectedYear} teamWin={teamWin[2]} awayWins={awayWins[2]} awayLosses={awayLosses[2]} awayTies={awayTies[2]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[3]} homeSelectionCity={homeSelectionCity[3]} homeSelectionTeam={homeSelectionTeam[3]} selectedYear={props.selectedYear} teamWin={teamWin[3]} homeWins={homeWins[3]} homeLosses={homeLosses[3]} homeTies={homeTies[3]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[3]} awaySelectionCity={awaySelectionCity[3]} awaySelectionTeam={awaySelectionTeam[3]} selectedYear={props.selectedYear} teamWin={teamWin[3]} awayWins={awayWins[3]} awayLosses={awayLosses[3]} awayTies={awayTies[3]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[4]} homeSelectionCity={homeSelectionCity[4]} homeSelectionTeam={homeSelectionTeam[4]} selectedYear={props.selectedYear} teamWin={teamWin[4]} homeWins={homeWins[4]} homeLosses={homeLosses[4]} homeTies={homeTies[4]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[4]} awaySelectionCity={awaySelectionCity[4]} awaySelectionTeam={awaySelectionTeam[4]} selectedYear={props.selectedYear} teamWin={teamWin[4]} awayWins={awayWins[4]} awayLosses={awayLosses[4]} awayTies={awayTies[4]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2">
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[5]} homeSelectionCity={homeSelectionCity[5]} homeSelectionTeam={homeSelectionTeam[5]} selectedYear={props.selectedYear} teamWin={teamWin[5]} homeWins={homeWins[5]} homeLosses={homeLosses[5]} homeTies={homeTies[5]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4">
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[5]} awaySelectionCity={awaySelectionCity[5]} awaySelectionTeam={awaySelectionTeam[5]} selectedYear={props.selectedYear} teamWin={teamWin[5]} awayWins={awayWins[5]} awayLosses={awayLosses[5]} awayTies={awayTies[5]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2" style={style}>
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[6]} homeSelectionCity={homeSelectionCity[6]} homeSelectionTeam={homeSelectionTeam[6]} selectedYear={props.selectedYear} teamWin={teamWin[6]} homeWins={homeWins[6]} homeLosses={homeLosses[6]} homeTies={homeTies[6]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4" style={style}>
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[6]} awaySelectionCity={awaySelectionCity[6]} awaySelectionTeam={awaySelectionTeam[6]} selectedYear={props.selectedYear} teamWin={teamWin[6]} awayWins={awayWins[6]} awayLosses={awayLosses[6]} awayTies={awayTies[6]}/>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2" style={style2}>
                        <LeftCard dropdownTheme={props.dropdownTheme} homeTeam={homeTeam[7]} homeSelectionCity={homeSelectionCity[7]} homeSelectionTeam={homeSelectionTeam[7]} selectedYear={props.selectedYear} teamWin={teamWin[7]} homeWins={homeWins[7]} homeLosses={homeLosses[7]} homeTies={homeTies[7]} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-5 mb-sm-4" style={style2}>
                        <RightCard dropdownTheme={props.dropdownTheme} awayTeam={awayTeam[7]} awaySelectionCity={awaySelectionCity[7]} awaySelectionTeam={awaySelectionTeam[7]} selectedYear={props.selectedYear} teamWin={teamWin[7]} awayWins={awayWins[7]} awayLosses={awayLosses[7]} awayTies={awayTies[7]}/>
                    </Col>

                </Row>
        </div>
    );
                            
};

export default Week;
