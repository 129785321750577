import React, { useState, useEffect, Fragment }  from 'react';
import Week from './Week';
import axios from "axios";
import currentWeekNumber from 'current-week-number';
import Tabs from 'react-responsive-tabs';
import Standings from './Standings';
import { ParallaxBanner } from 'react-scroll-parallax';

import '../tabStyles.css';

import {
    Container  //, Tab, Tabs
} from 'react-bootstrap';
var league = ''

function Scores(props) {

   /* var calendarWeek = "";
    const calendarWeek1 = currentWeekNumber();
    console.log("calendarWeek1 = " + calendarWeek1)
    if (calendarWeek1 < 32) {
        calendarWeek = 53
      } else {
        calendarWeek = calendarWeek1;
      }
    const defActKey = 'week' + currentWeek;
    const [leagues, setLeagues] = useState([]);
    const [key, setKey] = useState(defActKey);

    var currentWeek = "";
    const currentWeek1 = (+calendarWeek - +34);
    console.log("currentWeek1 = " + currentWeek1)

    if (currentWeek1 >= 19) {
        currentWeek = 18;
      } else if (currentWeek1 < 1) {
        currentWeek = 1;
      } else {
        currentWeek = currentWeek1
    }
    const selectedTab = (+currentWeek - +1); //seems like tab number starts at 0*/

    var yesterday = new Date(Date.now() - 86400000);  //use this in currentWeekNumber if weeks starts on Sunday
    const defActKey = 'week' + currentWeek;
    const [leagues, setLeagues] = useState([]);
    const [key, setKey] = useState(defActKey);
    const currentYear = 2023; 
    var currentWeek = "1";
    
    var calendarWeek = "";
    const calendarWeek1 = currentWeekNumber(yesterday); //used date of yesterday to include Monday in previous week
    
    if (calendarWeek1 < 32) {
        calendarWeek = 53
      } else {
        calendarWeek = calendarWeek1;
      }
    
    const currentWeek1 = (+calendarWeek - +35);

    if (currentWeek1 >= 19) {
        currentWeek = 18;
      } else if (currentWeek1 < 1) {
        currentWeek = 1;
      } else {
        currentWeek = currentWeek1
    }

    const selectedTab = (+currentWeek - +1); //seems like tab number starts at 0

    useEffect(()=>{
        const getLeagueData = async () => {
            const leagueResult = await axios.get("https://server.helmetleague.com/leagues")
                .then(res => {
                    setLeagues(res.data);
                })
        }
        // call the function
        getLeagueData()
        // make sure to catch any error
        .catch(console.error);;
       
    }, [])

    const leagueNames = leagues.map(league => league.leagueName);
    const leagueYear = props.dropdownTitle;
    const weekNo = props.weekNo;
    const weeks = [
            { weekNo:'1', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'2', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'3', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'4', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'5', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'6', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'7', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'8', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'9', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'10', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'11', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'12', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'13', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'14', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'15', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'16', leagueName: leagueNames[0], currentWeek: currentWeek }, 
            { weekNo:'17', leagueName: leagueNames[0], currentWeek: currentWeek },
            { weekNo:'18', leagueName: leagueNames[0], currentWeek: currentWeek }
        ];
    /*if (leagueYear < 2020) {
        delete weeks[17];
    }

    if (leagueYear == 2023) {
        for (let i = (+calendarWeek - +34); i < 20; i++) {
        //for (let i = (+currentWeek + +1); i < 19; i++) {
            delete weeks[i];
        }
    }
    if (leagueYear == 2023) {
        //for (let i = (+calendarWeek - +34); i < 20; i++) {
        for (let i = (+currentWeek + +1); i < 19; i++) {
            delete weeks[i];
        }
    }*/
    if (leagueYear < 2020) {
        weeks[17] = {...weeks[17], showTab: true}
        weeks[17] = {...weeks[17], hideTab: true}
    }

    if ((leagueYear == 2024)&&(typeof weeks[17] !== "undefined")){
        //for (let i = (+calendarWeek - +34); i < 20; i++) {
        for (let i = (+currentWeek + +1); i < 18; i++) {
            //delete weeks[i];
            //weeks[i] = {...weeks[i], showTab: "false"}
            weeks[i] = {...weeks[i], showTab: false}
        }

    }
    

    /*function getTabs() {
        return weeks.map(week => ({
                //key: index, // Optional. Equals to tab index if this property is omitted
                tabClassName: 'tab', // Optional
                panelClassName: 'panel', // Optional
                title: ('Week ' + week.weekNo),
                getContent: () => <Week key={props.dropdownTitle} leagueName={week.leagueName} weekNo={week.weekNo} currentWeek={currentWeek} selectedYear={props.dropdownTitle} dropdownTheme={props.dropdownTheme}/>,
            }));
    }*/
    function getTabs() {
        return weeks
        .filter(week => {
            return(week.showTab != false);
        })
        .map(week => ({

            //key: index, // Optional. Equals to tab index if this property is omitted
            tabClassName: 'tab', // Optional
            panelClassName: 'panel', // Optional
            title: ('Week ' + week.weekNo),
            disabled: week.hideTab,
            getContent: () => <Week key={props.dropdownTitle} leagueName={week.leagueName} weekNo={week.weekNo} currentWeek={currentWeek} selectedYear={props.dropdownTitle} dropdownTheme={props.dropdownTheme}/>

        }));
    
    }
 
    return (
        <Fragment>
        <div id="matchups" className="bg-light">
            <Container className="pb-3 pt-3">
                <div className="text-center shiftDown"> 
                    <Tabs transform={false} showInkBar={true} selectedTabKey={selectedTab} items={getTabs()}/>  
                    
                </div>
            </Container>   
        </div>
        <ParallaxBanner
            layers={[{ image: '/img/helmets.jpg', speed: -14 }]}
            className="helmets"
        />
        <Standings key={props.dropdownTitle} leagueName={leagueNames[0]} dropdownTitle={props.dropdownTitle} />
        <ParallaxBanner
            layers={[{ image: '/img/field.jpg', speed: -16 }]}
            className="field"
        />
        </Fragment>
    );
};

export default Scores;

/*
        <div id="matchups" className="bg-light">
            <Container className="pb-3 pt-3">
                <div className="text-center shiftDown"> 
                    <Tabs transform={false} showInkBar={true} selectedTabKey={selectedTab} items={getTabs()}/>  
                    
                </div>
            </Container>   
        </div>
        <ParallaxBanner
            layers={[{ image: '/img/helmets.jpg', speed: -14 }]}
            className="helmets"
        />
        <Standings key={props.dropdownTitle} leagueName={leagueNames[0]} dropdownTitle={props.dropdownTitle} />
        <ParallaxBanner
            layers={[{ image: '/img/field.jpg', speed: -16 }]}
            className="field"
        />
        */